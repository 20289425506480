import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private apiUrl = `${environment.apiUrl}/analytics`;

  constructor(private readonly http: HttpClient) {}

  sendAnalytics(type: string, data: unknown = {}) {
    this.http
      .post(`${this.apiUrl}/general`, {
        type,
        data,
      })
      .subscribe();
  }
}
